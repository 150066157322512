import React, { useState } from 'react';
// gatsby
import { graphql } from 'gatsby';
// layouts
import { MainLayout } from 'layouts';
// material
import { Typography, Grid } from '@mui/material';
// page components
import {
  BlogListing,
  BlogListingSmall,
  BlogSidebar,
  ContentWrapper,
  Heading,
  NewsletterCard,
} from 'pages-components/blog-list';
// components
import { CardBox } from 'components';
// hooks
import { useLocales } from 'hooks';

const BlogListPage = ({ data: { page, allMarkdown }, location, pageContext }: any) => {
  const { frontmatter: frontMatter } = page;
  const { edges } = allMarkdown;
  const { translate: t } = useLocales();
  const [showNewsletterHeader, setShowNewsletterHeader] = useState<boolean>(false);

  return (
    <MainLayout
      metadata={frontMatter?.metadata}
      path={location.pathname}
      locale={pageContext.locale}
    >
      <Heading
        background="default"
        title={frontMatter?.heading?.title}
        titleProps={{ variant: 'h1', component: 'h1' }}
      />

      <ContentWrapper
        sidebar={
          <BlogSidebar>
            <Grid container columnSpacing={3} rowSpacing={4}>
              <Grid item xs={12} sm={6} md={12}>
                <CardBox title={t('blog.latestArticles')}>
                  {edges.length ? (
                    <BlogListingSmall posts={edges.slice(0, 3)} />
                  ) : (
                    <Typography variant="body2" align="center" color="textSecondary">
                      {t('common.emptyPosts')}
                    </Typography>
                  )}
                </CardBox>
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <NewsletterCard
                  title={showNewsletterHeader ? t('newsletter.title') : undefined}
                  subtitle={showNewsletterHeader ? t('newsletter.subtitle') : undefined}
                  onLoad={() => setShowNewsletterHeader(true)}
                  onSend={() => setShowNewsletterHeader(false)}
                />
              </Grid>
            </Grid>
          </BlogSidebar>
        }
      >
        {edges.length ? (
          <BlogListing posts={edges} />
        ) : (
          <Typography variant="body1">{t('common.emptyPosts')}</Typography>
        )}
      </ContentWrapper>
    </MainLayout>
  );
};

export default BlogListPage;

export const pageQuery = graphql`
  query ($id: String!, $locale: String!, $localeRegex: String!) {
    allMarkdown: allMdx(
      filter: {
        slug: { ne: "root" }
        frontmatter: { post: { in: true } }
        fileAbsolutePath: { regex: $localeRegex }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            slug
            title
            date(formatString: "LL", locale: $locale)
            post
            time_read
            author {
              name
              image {
                src {
                  name
                  childImageSharp {
                    gatsbyImageData(width: 40, height: 40)
                  }
                }
                alt
                title
              }
            }
            main_photo {
              src {
                name
                childImageSharp {
                  gatsbyImageData(width: 343)
                }
              }
              alt
              title
            }
          }
          fileAbsolutePath
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        metadata {
          title
          description
          og_title
          og_description
          og_type
          og_image {
            publicURL
            name
          }
          fb_app_id
          twitter_card
          twitter_creator
          twitter_title
          twitter_description
          twitter_image {
            publicURL
            name
          }
          twitter_url
          keywords
        }
        heading {
          title
        }
      }
    }
  }
`;
